import React from "react";

const adVideo =
  "https://res.cloudinary.com/dl2c0qth2/video/upload/v1738481968/Advertisement/b9hgnrchamu7vork3gid.mp4";

const Modal = ({ show, onClose }) => {
  if (!show) return null;

  return (
    <div className="advertisement">
      <div className="modal-overlay" onClick={onClose}>
        <div className="modal-content" onClick={(e) => e.stopPropagation()}>
          <button className="modal-close-button" onClick={onClose}>
            &times;
          </button>

          {/* Video Element */}
          <video
            src={adVideo}
            className="modal-gif"
            autoPlay
            loop
            muted
            controls
          />
        </div>
      </div>
    </div>
  );
};

export default Modal;
