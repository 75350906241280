export const getSwiperOptions = (slides) => {
  const loop = slides > 1; // Only enable loop if there are more than one slide

  return {
    speed: 600,
    loop: loop,  // Disable loop if not enough slides
    autoplay: {
      delay: 5000,
      disableOnInteraction: false,
    },
    slidesPerView: "auto",
    pagination: {
      el: ".swiper-pagination",
      type: "bullets",
      clickable: true,
    },
    breakpoints: {
      320: {
        slidesPerView: 1,
        spaceBetween: 20,
      },
      1200: {
        slidesPerView: 3,
        spaceBetween: 20,
      },
    },
  };
};
