import React, { useEffect, useState } from "react";

// Cloudinary image links
const imageLinks = [
  "https://res.cloudinary.com/dl2c0qth2/image/upload/v1735995979/Amazon%20Announcement/cqcliafwjhbzneyoaulb.gif",
  "https://res.cloudinary.com/dl2c0qth2/image/upload/v1726386260/1_nk1djc.png", 
  "https://res.cloudinary.com/dl2c0qth2/image/upload/v1726386261/2_kbtfgn.png",
  "https://res.cloudinary.com/dl2c0qth2/image/upload/v1726386261/3_w4wh4q.png", 
  "https://res.cloudinary.com/dl2c0qth2/image/upload/v1726386262/4_umu1jh.png",
  "https://res.cloudinary.com/dl2c0qth2/image/upload/v1726386262/5_hqkkun.png", 
  "https://res.cloudinary.com/dl2c0qth2/image/upload/v1726386262/6_gj3912.png", 
  "https://res.cloudinary.com/dl2c0qth2/image/upload/v1726386264/7_so6ome.png", 
  "https://res.cloudinary.com/dl2c0qth2/image/upload/v1726386266/8_coovzu.png",
];

const Carousel = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [animationClasses, setAnimationClasses] = useState([]);

  // Initialize the animation classes when the component mounts
  useEffect(() => {
    const initialClasses = Array(imageLinks.length).fill("zoom-out");
    initialClasses[currentSlide] = "zoom-in";
    setAnimationClasses(initialClasses);
  }, [currentSlide]);

  // Change to the next slide
  const nextSlide = () => {
    setCurrentSlide((prev) => (prev + 1) % imageLinks.length); // Loop back to the first slide after the last one
  };

  // Handle image click to go to the next slide
  const handleImageClick = () => {
    nextSlide();
  };

  // Set an interval to automatically change slides every 3 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      nextSlide();
    }, 8000); // Change slide every 3 seconds

    // Clean up the interval when the component unmounts
    return () => clearInterval(interval);
  }, []); // Empty dependency array ensures this runs only once when the component mounts

  return (
    <section id="announcement" className="announcement">
      <div className="content">
        <div className="container">
          <div className="carousel">
            {/* Carousel Slides */}
            <div className="slides">
              {imageLinks.map((img, index) => (
                <div
                  key={index}
                  className={`slide ${animationClasses[index]}`}
                  style={{ display: currentSlide === index ? "block" : "none" }}
                  onClick={handleImageClick} // Click to go to the next slide
                >
                  <img
                    src={img}
                    alt={`Announcement slide ${index + 1}`}
                    className="img-fluid"
                  />
                </div>
              ))}
            </div>
          </div>

          {/* Hint below the carousel */}
          <div className="hint" data-aos="fade-in" data-aos-delay="400">
            <div className="inner">
              <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 11.8585V0.823975L8 8.82398H3.31035L3.03448 8.89294L0 11.8585Z" fill="#9A988B" />
                <path d="M6.27101 12.3415L3.78825 13.3759L0.546875 5.72076L3.0986 4.68628L6.27101 12.3415Z" fill="#9A988B" />
              </svg>
              <p>Hello... you can next by clicking on it</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Carousel;
