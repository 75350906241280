import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';


const Bookshelf = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  const books = [
    {
      href: "https://www.amazon.com/Do-No-Harm-Mary-McNaughton/dp/1970072059",
      src: "https://m.media-amazon.com/images/I/61Gtl32qvkL._SL1360_.jpg",
      delay: 250,
      factor: 0.04
    },
    {
      href: "https://www.amazon.com/Travel-Free-Soul-Natalie-Ollivier/dp/1952027209",
      src: "https://m.media-amazon.com/images/I/51Hu2WrUimL._SY445_SX342_.jpg",
      delay: 350,
      factor: 0.1
    },
    {
      href: "https://www.amazon.com/Destruction-Georgina-Fatseas/dp/1955136475",
      src: "https://m.media-amazon.com/images/I/61uz9INYCIL._SL1360_.jpg",
      delay: 200,
      factor: 0.03
    },
    {
      href: "https://www.amazon.com/Child-World-War-II-Writes/dp/1970072474",
      src: "https://m.media-amazon.com/images/I/81Wr6NPGHSL._SL1360_.jpg",
      delay: 200,
      factor: 0.05
    },
    {
      href: "https://www.amazon.com/Luck-Blind-Girl-Odiche-Ozoma/dp/1970072741",
      src: "https://m.media-amazon.com/images/I/71+RsEiGzAL._SL1360_.jpg",
      delay: 200,
      factor: 0.01
    },
    {
      href: "https://www.amazon.com/Learn-Me-Fr-Buckley/dp/1955136009",
      src: "https://m.media-amazon.com/images/I/7184pLlMZqL._SL1360_.jpg",
      delay: 250,
      factor: 0.03
    },
    {
      href: "https://www.amazon.com/Secret-River-Unshakeable-Faith-Koudou-ebook/dp/B0BZDLTWFC",
      src: "https://m.media-amazon.com/images/I/61J3WRUdpLL._SY425_.jpg",
      delay: 300,
      factor: 0.07
    },
    {
      href: "https://www.amazon.com/Darpa-Rise-Manuel-Pelaez/dp/1970072121",
      src: "https://m.media-amazon.com/images/I/81LabgWT2QL._SL1360_.jpg",
      delay: 300,
      factor: 0.05
    },
    {
      href: "https://www.amazon.com/Bond-Manuel-Pelaez/dp/1955136890/ref=monarch_sidesheet",
      src: "https://m.media-amazon.com/images/I/71LlT-jeLvL._SL1500_.jpg",
      delay: 250,
      factor: 0.02
    },
    {
      href: "https://www.amazon.com/Rupert-Mireille-Schwartz/dp/1952027055",
      src: "https://m.media-amazon.com/images/I/71mJd7JBhCL._SY425_.jpg",
      delay: 200,
      factor: 0.01
    },
    {
      href: "https://www.amazon.com/Whichever-Wind-Blows-Barbara-Pierce/dp/1955136068",
      src: "https://m.media-amazon.com/images/I/614D78fL5-S._SY425_.jpg",
      delay: 200,
      factor: 0.03
    },
    {
      href: "https://www.amazon.com/Roman-Teapot-Christmas-Adventure/dp/1952027691",
      src: "https://m.media-amazon.com/images/I/71TZgL0-BsL._SY425_.jpg",
      delay: 300,
      factor: 0.08
    },
    {
      href: "https://www.amazon.com/Profiles-3Disaster-Prone-Relationships-Minnie-Claiborne/dp/195202723",
      src: "https://m.media-amazon.com/images/I/61o2dkcN-mL._SY425_.jpg",
      delay: 350,
      factor: 0.09
    },
    {
      href: "https://www.amazon.com/Emperors-Regret-Barbara-Pierce/dp/1970072326",
      src: "https://m.media-amazon.com/images/I/71VKAO8HEEL._SY385_.jpg",
      delay: 400,
      factor: 0.1
    },
    {
      href: "https://www.amazon.com/Darpa-Genesis-Vol-Manuel-Pelaez/dp/1952027934",
      src: "https://m.media-amazon.com/images/I/71GseXXa8fL._SL1360_.jpg",
      delay: 300,
      factor: 0.04
    },
    {
      href: "https://www.amazon.com/Darpa-Mesial-Manuel-Pelaez/dp/1952027969",
      src: "https://m.media-amazon.com/images/I/716AxImnkTL._SL1360_.jpg",
      delay: 200,
      factor: 0.01
    },
    {
      href: "https://www.amazon.com/ALGERNON-TIGER-Thomas-Anthony-Bevan/dp/195202739X",
      src: "https://m.media-amazon.com/images/I/71lkiioFJuL._SL1360_.jpg",
      delay: 200,
      factor: 0.03
    },
    {
      href: "https://www.amazon.com/Holiday-Recollections-Barbara-Pierce/dp/1955136726",
      src: "https://m.media-amazon.com/images/I/81OauFkE+TL._SL1500_.jpg",
      delay: 200,
      factor: 0.08
    },
    {
      href: "https://www.amazon.com/Holiday-Adventures-Bonza-Sonja-Humpback/dp/1970072903",
      src: "https://m.media-amazon.com/images/I/71eiY3Qg4rL._SY425_.jpg",
      delay: 300,
      factor: 0.08
    },
    {
      href: "https://www.amazon.com/Remembering-Black-Trailblazers-Their-Legacies/dp/1970072113",
      src: "https://m.media-amazon.com/images/I/71FTKVaG3pL._SL1360_.jpg",
      delay: 400,
      factor: 0.08
    },
    {
      href: "https://www.amazon.com/Fibonacci-Tales-B/dp/1970072288",
      src: "https://m.media-amazon.com/images/I/71eQBSf8TYL._SL1360_.jpg",
      delay: 400,
      factor: 0.08
    }
  ];

  return (
    <section id="portfolio" className="portfolio">
      <div className="container">
        <div className="section-title mb-4">
          <h2>Bookshelf</h2>
          <p>What we've done</p>
        </div>
        <div className="row portfolio-container">
          <div className="iframeBody">
            <section className="module floating-books">
              <div className="content-wrapper">
                <div className="books">
                  {books.map((book, index) => (
                    <div
                      key={index}
                      className="book"
                      data-aos="fade-in"
                      data-aos-delay={book.delay}
                    >
                      <a href={book.href}>
                        <img src={book.src} alt="Book" />
                      </a>
                    </div>
                  ))}
                </div>
                {/* <div className="hint" data-aos="fade-in" data-aos-delay="400">
                  <div className="inner">
                    <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M0 11.8585V0.823975L8 8.82398H3.31035L3.03448 8.89294L0 11.8585Z" fill="#9A988B" />
                      <path d="M6.27101 12.3415L3.78825 13.3759L0.546875 5.72076L3.0986 4.68628L6.27101 12.3415Z" fill="#9A988B" />
                    </svg>
                    <p>Hello... you can explore a book by clicking on it</p>
                  </div>
                </div> */}
              </div>
            </section>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Bookshelf;
